import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import localeItIT from '@angular/common/locales/it';
import localeEnGB from '@angular/common/locales/en-GB';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import { RouterModule, RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpBackend, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// eCharts
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import langIT from 'echarts/lib/i18n/langIT';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import langEN from 'echarts/lib/i18n/langEN';

// i18n
import { createTranslateLoader, TranslationService } from './core/services/translation.service';

// Interceptors
import httpHeaders from './core/interceptor/http-headers.interceptor';
import errorHandler from './core/interceptor/error-handler.interceptor';

// Guards
import { checkRoles, isAuthenticatedGuard, isNotAuthenticatedGuard } from './core/guards/user.guard';

// Modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

// Enums
import { Roles } from './shared/enums/roles';

// Services
import { StorageService } from './core/services/storage.service';
import { AuthenticationService } from './core/authentication/authentication.service';

import { AppComponent } from './app.component';

registerLocaleData(localeItIT, 'it-IT');
registerLocaleData(localeEnGB, 'en-GB');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
echarts.registerLocale('it-IT', langIT as unknown);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
echarts.registerLocale('en-GB', langEN as unknown);

const routes: Routes = [
  {
    path: 'sign',
    loadChildren: () => import('./modules/sign/sign.module').then((m) => m.SignModule),
    canActivateChild: [isNotAuthenticatedGuard],
  },
  {
    path: 'order-creation',
    loadChildren: () => import('./modules/order-creation/order-creation.module').then((m) => m.OrderCreationModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.PharmacyUser, Roles.PharmacyAdmin)],
  },
  {
    path: 'orders-list',
    loadChildren: () => import('./modules/orders-list/orders-list.module').then((m) => m.OrdersListModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.PharmacyUser, Roles.IndustryAdmin, Roles.PharmacyAdmin, Roles.SuperAdmin)],
  },
  {
    path: 'upload-deals',
    loadChildren: () => import('./modules/upload-deals/upload-deals.module').then((m) => m.UploadDealsModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.IndustryAdmin)],
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.SuperAdmin)],
  },
  {
    path: 'companies',
    loadChildren: () => import('./modules/companies/companies.module').then((m) => m.CompaniesModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.IndustryAdmin, Roles.PharmacyAdmin, Roles.PharmacyUser, Roles.SuperAdmin)],
  },
  {
    path: 'check-availabilities',
    loadChildren: () => import('./modules/check-availabilities/check-availabilities.module').then((m) => m.CheckAvailabilitiesModule),
    canActivateChild: [isAuthenticatedGuard],
  },
  {
    path: 'predictions',
    loadChildren: () => import('./modules/predictions/predictions.module').then((m) => m.PredictionsModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.Hospital)],
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/products/products.module').then((m) => m.ProductsModule),
    canActivateChild: [isAuthenticatedGuard, checkRoles(Roles.IndustryAdmin)],
  },
  {
    path: '',
    redirectTo: '/orders-list',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/orders-list',
  },
];

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  // A map to store cached routes, using companyId as the key and DetachedRouteHandle as the value
  storedRoutes: Map<string, DetachedRouteHandle> = new Map();

  /**
   * Determines if a route should be detached (cached) when navigating away from it.
   * This implementation only detaches the route if it matches the `/companies/:companyId` pattern.
   *
   * @param route - The route snapshot of the route being navigated away from
   * @returns `true` if the route should be cached, `false` otherwise
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // Cache only routes with the `/companies/:companyId` pattern
    return route.routeConfig?.path === ':companyId';
  }

  /**
   * Stores the detached route's handle (cached component instance) for reuse later.
   * The component is only cached if the route matches `/companies/:companyId` and
   * has a valid `companyId` parameter.
   *
   * @param route - The route snapshot of the route being detached
   * @param handle - The detached route handle (component instance) to store
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (route.routeConfig?.path === ':companyId' && handle) {
      const companyId = route.params['companyId'] as string | undefined;
      if (companyId) {
        // Store the handle in the map using companyId as the key
        this.storedRoutes.set(companyId, handle);
      }
    }
  }

  /**
   * Determines if a stored route should be reattached when navigating back to it.
   * This implementation only reattaches the route if it matches `/companies/:companyId`
   * and there is a cached instance for the specified `companyId`.
   *
   * @param route - The route snapshot of the route being navigated to
   * @returns `true` if the route should be reattached from cache, `false` otherwise
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const companyId = route.params['companyId'] as string | undefined;
    // Check if the route matches `/companies/:companyId` and if there's a cached instance for `companyId`
    return route.routeConfig?.path === ':companyId' && !!companyId && this.storedRoutes.has(companyId);
  }

  /**
   * Retrieves the stored route handle (cached component instance) for the specified route.
   * Returns the cached instance if it exists for the given `companyId`, or `null` if not found.
   *
   * @param route - The route snapshot of the route being navigated to
   * @returns The detached route handle (cached instance) if available, or `null` if not cached
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const companyId = route.params['companyId'] as string | undefined;
    // Retrieve the cached route handle for `companyId` if it exists, or return `null`
    return companyId ? this.storedRoutes.get(companyId) || null : null;
  }

  /**
   * Determines if a route should be reused when the navigation has the same route configuration.
   * This method allows the router to reuse the route if the future and current route configurations match.
   *
   * @param future - The route snapshot of the destination route
   * @param curr - The route snapshot of the current route
   * @returns `true` if the route should be reused, `false` otherwise
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Reuse the route if the configurations match
    return future.routeConfig === curr.routeConfig;
  }
}

function initializeAppFactory(
  storage: StorageService,
  translation: TranslationService,
  authService: AuthenticationService
): () => Promise<void> {
  return () =>
    new Promise<void>((resolve) => {
      // const currentLanguage = storage.getFromStorage<Language>(StorageKey.CurrentLanguage);
      // translation.setDefaultLanguage(currentLanguage ?? 'en-GB');
      translation.setDefaultLanguage('it-IT');

      authService.recoverSession();
      resolve();
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    NgxEchartsModule.forRoot({ echarts }),
    CoreModule,
    SharedModule,
  ],
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([httpHeaders, errorHandler])),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [StorageService, TranslationService, AuthenticationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
